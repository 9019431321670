import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '../js/auth.js'; // 导入身份验证服务


Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: () => import('../views/home.vue'),
		meta: {
			title: "首页",
		},
	},
	{
		path: '/news',
		name: 'news',
		component: () => import('../views/news.vue'),
		meta: {
			title: "公司新闻",
		},
	},
	{
		path: '/joinUs',
		name: 'joinUs',
		component: () => import('../views/joinUs.vue'),
		meta: {
			title: "招聘信息",
		},
	},
	{
		path: '/case_article',
		name: 'case_article',
		component: () => import('../views/case_article.vue'),
		meta: {
			title: "案例详情",
		},
	},
	{
		path: '/ITSolution',
		name: 'ITSolution',
		component: () => import('../views/Solution/ITSolution.vue'),
		meta: {
			title: "IT解决方案",
		},
	},
	{
		path: '/servise',
		name: 'servise',
		component: () => import('../views/servise.vue'),
		meta: {
			title: "运维服务",
		},
	},
	{
		path: '/successCase',
		name: 'successCase',
		component: () => import('../views/successCase.vue'),
		meta: {
			title: "成功案例",
		},
	},
	{
		path: '/CompanyProfile',
		name: 'CompanyProfile',
		component: () => import('../views/aboutUs/CompanyProfile.vue'),
		meta: {
			title: "公司简介",
		},
	},
	{
		path: '/corporateCulture',
		name: 'corporateCulture',
		component: () => import('../views/aboutUs/corporateCulture.vue'),
		meta: {
			title: "企业文化",
		},
	},
	{
		path: '/history',
		name: 'history',
		component: () => import('../views/aboutUs/history.vue'),
		meta: {
			title: "发展历程",
		},
	},
	{
		path: '/honor',
		name: 'honor',
		component: () => import('../views/aboutUs/honor.vue'),
		meta: {
			title: "荣誉资质",
		},
	},
	{
		path: '/IndustryNews',
		name: 'IndustryNews',
		component: () => import('../views/news/IndustryNews.vue'),
		meta: {
			title: "行业新闻",
		},
	},
	{
		path: '/CompanyNews',
		name: 'CompanyNews',
		component: () => import('../views/news/CompanyNews.vue'),
		meta: {
			title: "公司新闻",
		},
	},
	{
		path: '/organization',
		name: 'organization',
		component: () => import('../views/aboutUs/organization.vue'),
		meta: {
			title: "组织结构",
		},
	},
	{
		path: '/article',
		name: 'article',
		component: () => import('../views/article.vue'),
		meta: {
			title: "文章",
		},
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login.vue'),
		meta: {
			title: "登录",
		},
	},
	{
		
		path: '/successCase',
		name: 'successCase',
		component: () => import('../views/successCase.vue'),
		meta: {
			title: "成功案例",
		},
	},
		
	// {
	//   path: '/admin_article',
	//   name: 'admin_article',
	//   component: () => import('../views/admin_article.vue'),
	// 	meta: {
	// 		title: "编辑文章",
	// requiresAuth: true
	// 	},
	// },
	{
		path: '/HomeView',
		name: 'HomeView',
		component: () => import('../views/HomeView'),
		meta: {
			title: "后台管理",
			requiresAuth: true
		},
		children: [
			{
				path: '',
				name: 'manageNews',
				component: () => import('../components/manage/manageNews'),
				meta: {
					title: "文章管理",
					
				}
			},
			{
				path: '/conpanyNews',
				name: 'conpanyNews',
				component: () => import('../components/manage/conpanyNews'),
				meta: {
					title: "文章发布",
					
				}
			},
			{
				path: '/releaseCase',
				name: 'releaseCase',
				component: () => import('../components/manage/releaseCase'),
				meta: {
					title: "案例发布",
					
				}
			},
			{
				path: '/manegeCase',
				name: 'manegeCase',
				component: () => import('../components/manage/manegeCase'),
				meta: {
					title: "案例管理",
					
				}
			},
		]
	},
]


const router = new VueRouter({
	mode: 'hash',
	routes
})

router.beforeEach((to, from, next) => {
	// 验证是否需要身份验证
	if (to.meta.requiresAuth) {
		// 需要身份验证
		if (!auth.isAuthenticated()) {
			// 用户未认证，重定向到登录页面
			next('/login');
		} else {
			// 用户已认证，继续导航
			next();
		}
	} else {
		// 不需要身份验证，继续导航
		next();
	}
});

export default router
