import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'swiper/swiper-bundle.css';
import dataV from '@jiaminghi/data-view';

import './style/owl.theme.css';
import './style/fontawesome-all.css';
import './style/JiSlider.css';
import './style/owl.carousel.css';
// import './style/single.css';
import './style/style.css';

import "./style/font.css";

import VueQuillEditor from 'vue-quill-editor'
 
// 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
 
Vue.use(VueQuillEditor)


Vue.use(dataV)

Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
